import React from 'react';
import { Dialog } from '@reach/dialog';

import { Link } from 'gatsby';
import styled from 'styled-components';
import { theme } from 'styles/styled';
import Heading from 'components/Heading/Heading';
import { usePdfLinkCreator } from 'settings/extract-pdf-urls';

import { useTranslations } from 'settings/i18n';
import useIsMobile from 'hooks/useIsMobile';
import useToggle from 'hooks/useToggle';

import CloseIcon from 'images/Icons/CloseIcon';

export const Main = styled.main`
  overflow-x: hidden;
  background: ${theme.palette.secondaryLight};
  padding-top: 100px;
  padding-bottom: 150px;

  @media ${theme.devices.large} {
    padding-top: 180px;
  }
`;

export const Wrapper = styled.div`
  max-width: 960px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

export const Trapezoid = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  width: 100%;
  padding: 0;
  clip-path: polygon(0 7.5%, 100% 0, 100% 100%, 0 92.5%);
  min-height: 480px;
  margin-top: 20px;

  @media ${theme.devices.large} {
    clip-path: polygon(0 23%, 100% 0, 100% 100%, 0 77%);
    padding: 140px 0;
    min-height: 628px;
    margin-top: -48px;
  }
`;

export const Image = styled.img`
  width: 100%;
  max-width: 410px;
  height: 100%;
  max-height: 150px;
  margin-top: 48px;

  @media ${theme.devices.medium} {
    margin-top: 0;
    max-height: 280px;
  }
`;

export const ActionButton = styled(Link)`
  border-radius: 20px;
  background-color: ${theme.palette.primaryDisabled};
  padding: 12px 20px;
  font-size: 14.9px;
  font-weight: ${theme.fontWeight.medium};
  color: ${theme.palette.primary};
  text-transform: uppercase;
  margin-right: 10px;

  :hover {
    background-color: #28aaff;
    color: white;
    cursor: pointer;
  }

  :last-of-type {
    margin-right: 0;
  }
`;

export const TrapezoidWrapper = styled(Wrapper)`
  z-index: 2;
  display: flex;
  justify-content: center;
  flex-wrap: wrap-reverse;
  align-items: center;
  min-height: 250px;
`;

export const Excerpt = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 310px;
  margin-left: 30px;
  padding-left: 14px;

  @media ${theme.devices.small} {
    max-width: 510px;
  }
`;

export const ActionButtons = styled.div`
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  justify-content: flex-start;
  min-height: 100px;
  align-items: center;

  @media ${theme.devices.small} {
    min-height: auto;
  }
`;

export const Line = styled.div`
  z-index: 1;
  position: absolute;
  max-width: 960px;
  width: calc(100% - 30px);
  margin-left: auto;
  margin-right: auto;
  border-left: 1px solid #c2e7ff;
  top: 0;
  left: 30px;
  bottom: 0;
  right: 0;

  * {
    position: absolute;
    top: 100px;
    left: -25px;
    color: #c9e6fc;
    background: white;
    font-size: 80px;
  }

  @media ${theme.devices.large} {
    left: 0;
    width: 100%;

    * {
      top: 200px;
    }
  }
`;

export const CryptocurrencyHeading = styled(Heading)`
  margin-top: 0;

  @media ${theme.devices.xlarge} {
    margin-top: 50px;
  }
`;

export const OrnamentTrapezoid = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 2;
  background: #5ebfff;
  clip-path: polygon(0 0, 100% 81%, 100% 100%, 0 76%);
  height: 42px;
  width: 100%;

  @media ${theme.devices.large} {
    clip-path: polygon(0 0, 100% 84%, 100% 100%, 0 55%);
    height: 176px;
    bottom: -10px;
  }
`;

export const ReversedOrnamentTrapezoid = styled(OrnamentTrapezoid)`
  background: #5ebfff;
  clip-path: polygon(0 76%, 100% 0, 100% 81%, 0% 100%);

  @media ${theme.devices.large} {
    clip-path: polygon(0 84%, 100% 0, 100% 55%, 0% 100%);
  }
`;

export const RelativeContainer = styled.div<{ isRtl: boolean }>`
  position: relative;
  margin-top: ${(props) => (props.isRtl ? '36px' : '0')};
`;

export const ReversedTrapezoid = styled(Trapezoid)`
  background: #e9f6ff;
  clip-path: polygon(100% 7.5%, 0 0, 0 100%, 100% 92.5%);
  margin-top: -38px;

  @media ${theme.devices.large} {
    clip-path: polygon(100% 23%, 0 0, 0 100%, 100% 77%);
    margin-top: -144px;
  }
`;

export const ThirdSection = styled(Trapezoid)`
  margin-top: -38px;

  @media ${theme.devices.large} {
    margin-top: -144px;
  }
`;

export const ReversedImage = styled(Image)`
  order: 2;

  @media ${theme.devices.large} {
    order: 1;
  }
`;

export const ReversedExcerpt = styled(Excerpt)`
  padding-left: 14px;
  order: 1;

  @media ${theme.devices.large} {
    padding-left: 90px;
    order: 2;
  }
`;

export const ReversedLine = styled(Line)`
  max-width: 50%;
  left: 30px;
  @media ${theme.devices.large} {
    left: 50%;
  }
`;

export const ModalCloseIcon = styled(CloseIcon)`
  position: absolute;
  right: 0;
  top: -46px;
  :hover {
    cursor: pointer;
    opacity: 0.5;
  }
`;

export const VideoModal = styled(Dialog)`
  position: relative;
  z-index: 1000;
  margin: calc(50vh - 546px / 2) 20px;
  margin-top: 150px;
  width: calc(100% - 40px);
  max-width: 960px;
  height: 320px;
  padding: 0;

  @media ${theme.devices.small} {
    height: 100%;
    max-height: 546px;
  }

  @media ${theme.devices.large} {
    margin: calc(50vh - 546px / 2) auto;
    width: 100%;
  }
`;

export const EducationVideoModal = ({
  children,
  ...rest
}: JSX.IntrinsicElements['iframe']) => {
  const {
    on: isVideoModalVisible,
    setOn: showVideoModal,
    setOff: hideVideoModal,
  } = useToggle();

  return (
    <>
      <ActionButton to="#" onClick={showVideoModal}>
        {children}
      </ActionButton>
      {isVideoModalVisible && (
        <VideoModal
          onDismiss={hideVideoModal}
          aria-label="Education video modal">
          <ModalCloseIcon onClick={hideVideoModal} />
          <iframe
            title="Education video"
            width="100%"
            height="100%"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            {...rest}
          />
        </VideoModal>
      )}
    </>
  );
};

export const PdfActionButton = (props: { pdfLink: string }) => {
  const t = useTranslations();
  const isMobile = useIsMobile();
  const createPdfLink = usePdfLinkCreator();

  return isMobile ? (
    <ActionButton as="a" href={createPdfLink(props.pdfLink).to} target="_blank">
      {t('pdf')}
    </ActionButton>
  ) : (
    <ActionButton {...createPdfLink(props.pdfLink)}>{t('pdf')}</ActionButton>
  );
};
