import qs from 'qs';
import { GatsbyLinkProps } from 'gatsby';

import { Translation } from 'settings/i18n';
import menuLinks from 'settings/menuLinks';
import useLinkCreator from 'hooks/useLinkCreator';
import useIsMobile from 'hooks/useIsMobile';

type DefaultData = {
  education: Translation;
};

export default function extractPdfUrlsFactory<T extends DefaultData>(data: T) {
  return function extractPdfUrls(
    key: string,
    {
      fallbackMobile,
      fallbackDesktop,
    }: { fallbackMobile: string; fallbackDesktop: string },
  ) {
    return {
      desktop:
        ((data.education.translation[`${key}PdfDesktop`] as unknown) as {
          slug: string;
        })?.slug ?? fallbackDesktop,
      mobile:
        ((data.education.translation[`${key}PdfMobile`] as unknown) as {
          slug: string;
        })?.slug ?? fallbackMobile,
    };
  };
}

export function usePdfLinkCreator(): (
  pdfFileUrl: string,
) => { to: GatsbyLinkProps<{}>['to']; state: GatsbyLinkProps<{}>['state'] } {
  const isMobile = useIsMobile();
  const createLink = useLinkCreator();

  return (pdfFileUrl: string) => {
    const pdfFileUrlWoLeadingSlash = pdfFileUrl.startsWith('/')
      ? pdfFileUrl.substring(1)
      : pdfFileUrl;

    return {
      to: isMobile
        ? `/${pdfFileUrlWoLeadingSlash}`
        : createLink(
            menuLinks.educationPdfPreview +
              qs.stringify(
                {
                  file: pdfFileUrlWoLeadingSlash,
                },
                { addQueryPrefix: true },
              ),
          ),
      /* eslint-disable no-restricted-globals */
      state: { prevPath: location.pathname },
    };
  };
}
