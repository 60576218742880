import '@reach/dialog/styles.css';

import { Dialog } from '@reach/dialog';
import styled from 'styled-components';
import { theme } from 'styles/styled';

import {
  ModalCloseIcon,
  Wrapper,
} from 'components/EducationSection/EducationSection';
import FilledButton from 'components/Button/FilledButton';
import GhostButton from 'components/Button/GhostButton';
import TransparentButton from 'components/Button/TransparentButton';
import Heading from 'components/Heading/Heading';
import Text from 'components/Text/Text';

export const QuizWrapper = styled(Wrapper)`
  padding: 124px 20px 124px 20px;
  min-height: calc(100vh - 229px);

  @media ${theme.devices.medium} {
    padding-top: 220px;
    padding-bottom: 200px;
  }
`;

export const CompletedScreenWrapper = styled(Wrapper)`
  margin-top: 130px;
  padding-bottom: 100px;
  min-height: calc(100vh - 229px);

  @media ${theme.devices.medium} {
    margin-top: 212px;
    padding-bottom: 200px;
  }
`;

export const QuizStats = styled.section`
  display: grid;
  grid-template-columns: minmax(60px, max-content) auto 32px;
  grid-column-gap: 40px;
  align-items: center;
  justify-items: center;

  @media ${theme.devices.medium} {
    justify-items: initial;
  }
`;

export const QuizText = styled(Text)`
  color: ${theme.palette.primary};
  background-color: ${theme.palette.secondaryLight};
  border-radius: 15.5px;
  padding: 8px 15px;
  place-self: start;
  text-transform: uppercase;
  font-weight: ${theme.fontWeight.medium};
  font-size: 11px;
  white-space: nowrap;

  @media ${theme.devices.medium} {
    font-size: ${theme.fontSize.copy};
  }
`;

export const QuizLine = styled.div`
  visibility: hidden;
  height: 2px;
  width: 100%;
  background: ${theme.palette.secondaryLight};
  place-self: center;

  @media ${theme.devices.medium} {
    visibility: visible;
  }
`;

export const MobileQuizLine = styled(QuizLine)`
  margin-top: 12px;
  visibility: visible;

  @media ${theme.devices.medium} {
    visibility: hidden;
  }
`;

export const QuizQuestion = styled(Text)`
  font-size: 20px;
  margin: 24px auto;
  max-width: 664px;
  text-align: center;
  font-weight: ${theme.fontWeight.medium};
  letter-spacing: 0.17px;
  line-height normal;

  @media ${theme.devices.medium} {
    font-size: 36px;
    letter-spacing: 0.3px;
    margin: 48px auto 24px auto;
  }
`;

export const QuizNumberOfQuestions = styled.strong`
  display: block;
  text-align: center;
  color: ${theme.palette.primary};
  text-transform: uppercase;
  margin-bottom: 140px;

  @media ${theme.devices.medium} {
    margin-bottom: 196px;
  }
`;

export const QuizStartButton = styled(FilledButton)`
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const NextQuestionButton = styled(QuizStartButton)`
  margin-top: 40px;
`;

export const Answers = styled.ul`
  display: grid;
  grid-row-gap: 13px;
  margin-top: 16px;

  @media ${theme.devices.medium} {
    margin-top: 60px;
  }
`;

export const AnswerButton = styled(TransparentButton)<{ selected: boolean }>`
  border: 1px solid #f0f0f0;
  padding: 21px 38px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  color: ${(props) =>
    props.selected ? theme.palette.primary : theme.palette.dark};

  font-weight: ${theme.fontWeight.light};
  background: ${(props) => (props.selected ? '#e9f6ff' : theme.palette.light)};

  :hover {
    background: #e9f6ff;
    color: ${theme.palette.primary};
  }
`;

export const CurrentQuestionText = styled.strong`
  display: flex;
  align-items: center;
  color: ${theme.palette.primary};
  font-weight: ${theme.fontWeight.medium};
`;

export const QuestionTiles = styled.ul`
  display: none;
  height: 2px;
  width: 100%;
  margin-left: 20px;

  @media ${theme.devices.medium} {
    display: flex;
  }
`;

export const MobileQuestionTiles = styled(QuestionTiles)`
  display: flex;
  margin-top: 10px;
  margin-left: 10px;

  @media ${theme.devices.medium} {
    display: none;
  }
`;

export const QuestionTile = styled.li<{
  numberOfQuestions: number;
  active: boolean;
}>`
  flex-basis: calc(1 / ${(props) => props.numberOfQuestions} * 100%);
  background: ${(props) =>
    props.active ? theme.palette.primary : theme.palette.secondaryLight};
  margin-right: 10px;
`;

export const CompletedQuizImage = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 80px;
  width: 80px;
  margin-bottom: 30px;

  @media ${theme.devices.medium} {
    height: 140px;
    width: 140px;
    margin-bottom: 60px;
  }
`;

export const CompletedHeading = styled(Heading)`
  display: block;
  text-align: center;
  font-size: ${theme.fontSize.pageTitleMobile};
  margin-bottom: 20px;
  padding: 0 20px;

  @media ${theme.devices.medium} {
    margin-bottom: 30px;
    font-size: ${theme.fontSize.headingMedium};
  }
`;

export const QuizScoreText = styled(Text)`
  text-align: center;
  margin-bottom: 24px;
  text-transform: uppercase;
  font-size: 15px;
  color: ${theme.palette.primary};
  font-weight: ${theme.fontWeight.medium};
`;

export const ShareQuizResultsText = styled(Text)`
  display: block;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: ${theme.fontWeight.medium};
  text-align: center;
`;

export const SocialMediaIcons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 52px;

  * {
    outline: none;

    :hover {
      opacity: 0.9;
    }
  }

  @media ${theme.devices.medium} {
    margin-bottom: 80px;
  }
`;

export const SocialMediaIcon = styled.img`
  margin-right: 15px;
  height: 34px;

  @media ${theme.devices.medium} {
    height: 42px;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 60px;
  margin-right: 60px;

  @media ${theme.devices.small} {
    flex-direction: row;
  }
`;

export const TryOtherQuizzesButton = styled(FilledButton)`
  margin-bottom: 10px;
  font-size: 15px;
  margin-right: 0;

  @media ${theme.devices.small} {
    margin-right: 20px;
  }
`;

export const EducationButton = styled(GhostButton)`
  margin-bottom: 10px;
  font-size: ${theme.fontSize.copy};
  min-width: 200px;
`;

export const CancelQuizDialog = styled(Dialog)`
  position: relative;
  padding: 40px;
  margin: 33% 20px 0 20px;
  width: calc(100% - 40px);
  max-width: none;

  @media ${theme.devices.medium} {
    margin: 17.5% auto 0 auto;
    padding: 50px 80px 40px 80px;
    max-width: 770px;
  }
`;

export const AreYouSureCancellationText = styled(Heading)`
  margin-bottom: 48px;
  width: 100%;
`;

export const CancelQuizText = styled(Text)`
  margin-bottom: 45px;
`;

export const QuizCancellationButtonsWrapper = styled(Text)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const ContinueQuizButton = styled(FilledButton)`
  margin-bottom: 10px;
  margin-right: 16px;
`;

export const CancelQuizButton = styled(GhostButton)`
  margin-right: 16px;
  margin-bottom: 10px;
`;

export const CancelQuizModalCloseIcon = styled(ModalCloseIcon)`
  top: 20px;
  right: 20px;
  height: 20px;
  width: 20px;
`;
